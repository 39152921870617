.wrap-btn-future {
  position: absolute;
  z-index: 999;
  top: 20px;
  right: 20px;

  button {
    border: 0px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.22);
    height: 40px;
    width: 130px;
    &.future-off {
      background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(5px);
    }

    span {
      color: #ffffff;
    }
  }
}
