@import './common';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  overflow: hidden;
  overscroll-behavior: contain;
}

.bg,
canvas {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  cursor: grab;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  background-color: #7A8A57;
  font-family: $font-family-sans-serif;
}

.page {
  background-color: #eee;
  /* background-image: url("https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg"); */
  min-height: 100vh;
  /* background-repeat: no-repeat;
  background-size: 100% 100%; */
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888850;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #55555550;
}

/* fade in/out animation */
.fade-item-enter {
  opacity: 0;
}

.fade-item-enter-active {
  opacity: 1;
  transition: opacity 125ms ease-in;
}

.fade-item-exit {
  opacity: 1;
}

.fade-item-exit-active {
  opacity: 0;
  transition: opacity 125ms ease-in;
}

/* fade left animation */
.fade-left-enter {
  margin-left: -100%;
  opacity: 0;
}

.fade-left-enter-active {
  margin-left: 0;
  opacity: 1;
  transition: all 500ms linear;
}

.fade-left-exit {
  margin-left: 0;
  opacity: 1;
}

.fade-left-exit-active {
  margin-left: -100%;
  opacity: 0;
  transition: all 500ms linear;
}

/* fade right animation */
.fade-right-enter {
  right: -100%;
  opacity: 0;
}

.fade-right-enter-active {
  right: 0;
  opacity: 1;
  transition: all 500ms linear;
}

.fade-right-exit {
  right: 0;
  opacity: 1;
}

.fade-right-exit-active {
  right: -100%;
  opacity: 0;
  transition: all 500ms linear;
}

.rs-base.hide {
  display: none;
}

/* fade bottom animation */
.fade-bottom-enter {
  bottom: -100%;
  opacity: 0;
}

.fade-bottom-enter-active {
  bottom: 0;
  opacity: 1;
  transition: all 500ms linear;
}

.fade-bottom-exit {
  bottom: 0;
  opacity: 1;
}

.fade-bottom-exit-active {
  bottom: -100%;
  opacity: 0;
  transition: all 500ms linear;
}

/* fade up big */
.fade-up-big {
  margin-top: 200%;
  opacity: 1;
}
.fade-up-big-enter-active {
  margin-top: 0;
  opacity: 1;
  transition: all 500ms ease;
}
.fade-up-big-exit {
  margin-top: 0;
  opacity: 1;
}
.fade-up-big-exit-active {
  margin-top: 200%;
  opacity: 1;
  transition: all 500ms ease;
}

/* Fade up apart */
.fade-up-apart {
  top: 0;
  opacity: 1;
}
.fade-up-apart-enter-active {
  top: calc(100% - 90px);
  opacity: 1;
  transition: all 500ms ease;
  .tray {
    height: 0px;
  }
}
.fade-up-apart-enter-done {
  top: calc(100% - 90px);
  opacity: 1;
  .tray {
    height: 0px;
  }
}
.fade-up-apart-exit {
  top: calc(100% - 90px);
  opacity: 1;
}
.fade-up-apart-exit-active {
  top: 0;
  opacity: 1;
  transition: all 500ms ease;
}
.fade-up-apart-exit-done {
  top: 0;
  opacity: 1;
  transition: all 500ms ease;

}

.invisible {
  display: none;
}
