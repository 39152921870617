.wrap-bottom-navigation-mobile {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 0;
  height: 70px;
  background-color: #000030;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item {
    text-transform: uppercase;
    height: 100%;
    padding: 10px;
    cursor: pointer;

    span {
      font-family: 'GT Walsheim Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      text-transform: uppercase;
      color: #ffffff;
      opacity: 0.4;
    }

    svg {
      opacity: 0.4;
      margin-bottom: 5px;
    }

    &.active {
      span {
        opacity: 1;
      }

      svg {
        opacity: 1;
      }
    }
  }
}
