.wrap-location-detail-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1060;
  background: rgba(45, 45, 45, 0.5);
  backdrop-filter: blur(5.5px);

  .tray {
    height: 40px;
  }

  .content {
    background-color: #FFFFFF;
    height: calc(100% - 40px);
    width: 100%;
    padding-bottom: 20px;

    .header {
      background-color: #000030;
      padding: 20px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ffffff;
      padding-top: 10px;
      padding-bottom: 20px;

      button {
        width: 40px;
        height: 40px;
        border: 0px;
        background: rgba(255, 255, 255, 0.1);
        color: #ffffff;
        border-radius: 0px;
        padding: 0px;

        .close-btn {
          fill: none;
          stroke-width: 2.5px;
          width: 50%;
          height: 50%;
          stroke: #ffffff;
        }
      }

      span {
        font-family: 'GT Walsheim Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
        text-align: center;
      }
    }

    .body {
      color: #FFFFFF;
      height: calc(100% - 70px);
      overflow: hidden;

      .wrap-gallery {
        width: 100%;
        height: 220px;
        overflow: hidden;
        padding-bottom: 20px;
        position: relative;

        .wrap-360-btn {
          position: absolute;
          top: 20px;
          right: 20px;
          z-index: 1;
        }

        .slick-slider {
          height: 100%;

          .slick-list {
            height: 100%;
            width: 100%;

            .slick-track {
              height: 100%;
              width: 100%;

              .slick-slide {
                width: 100%;
                height: 100%;

                &.slick-active.slick-current {
                  height: 100%;
                  width: 100%;

                  div {
                    height: 100%;
                    width: 100%;

                    img {
                      object-fit: cover !important;
                      object-position: center;
                    }
                  }
                }
              }
            }
          }

          .slick-dots {
            bottom: 5px;
            li {
              width: 10px;
              height: 10px;
              button {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                padding: 0px;

                &::before {
                  content: '';
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: rgba(255, 255, 255, 0.42);
                  backdrop-filter: blur(5px);
                }
              }
              &.slick-active {
                button {
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;

                  &::before {
                    background: #FFFFFF;
                  }
                }
              }
            }
          }
        }
      }
      .wrap-description {
        background: #FFFFFF;
        height: calc(100% - 200px);
        padding: 0px 25px;
        overflow-y: auto;

        font-family: 'PT Serif';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 135%;
        color: #000000;
        .future-development {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 20px;
        }
        .color-key {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
      }
    }
  }
}
