.wrap-loading-mobile {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000030;
  z-index: 1060;

  .wrap-content {
    position: relative;

    .header {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 1061;
      height: 40px;

      img {
        height: 100%;
      }
    }

    .wrap-intro-content {
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: 0px;
      color: #fff;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      background-size: cover;
      background-position: center;

      &.hide {
        display: none;
      }
      .intro-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-bottom: 40px;
        width: 100%;
        .wrap-text {
          font-family: 'GT Walsheim Pro';
          font-style: normal;
          font-weight: 100;
          font-size: 40px;
          line-height: 69px;
          text-align: center;
          letter-spacing: 0.075em;
          color: #FFFFFF;
        }

        .wrap-button {
          height: 102px;
          button {
            background: transparent;
            color: #ffffff;
            font-weight: bold;
            border: 3px solid #ffffff;
            font-size: 16px;
            width: 262px;
            padding: 0px;
            height: 40px;

            .btn-arrow {
              width: 40px;
              border-right: 3px solid #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .btn-text {
              color: #ffffff;
              font-weight: bold;
              font-size: 16px;
              text-decoration: none;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        h1 {
          font-size: calc(2rem + 2vw);
          font-family: 'Philosopher', serif;
          margin-bottom: 1rem;
        }

        p {
          font-size: calc(1rem + 0.125vw);
          letter-spacing: 0.125rem;
          font-weight: 400;
          margin-bottom: 0.5rem;
        }
      }
    }
    .wrap-intro-video {
      transition: all 1s;

      &.hide {
        opacity: 0;
        display: none;
        pointer-events: none;
      }

      &.show {
        opacity: 1;
        pointer-events: unset;
      }

      .intro-video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        object-fit: contain;
        z-index: -1;
      }

      .wrap-btn-control-video {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 90;

        button {
          background: rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(5px);
          border: 0px;
          width: fit-content;
          height: 40px;
          color: #FFFFFF;
          font-family: 'GT Walsheim Pro';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 17px;
          opacity: 0.8;
          text-align: center;
        }
      }
    }
  }
}
