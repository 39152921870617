.wrap-gallery-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #ffffff;

  .header {
    padding-top: 20px;
    padding-bottom: 20px;
    position: sticky;
    top: 0;
    background-color: #000030;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    .logo {
      position: absolute;
      left: 20px;
      height: 40px;
      width: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    span {
      font-family: 'GT Walsheim Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
    }
  }

  .body {
    height: calc(100% - 150px);
    padding: 10px 10px 20px 10px;
    overflow: auto;

    .wrap-list-images {
      display: grid;
      gap: 10px;
      grid-template-columns: auto auto;

      .item {
        height: 100px;

        div {
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.wrap-fancy-box-mobile.fancybox__container {
  z-index: 999;

  .fancybox__toolbar {
    top: 20px;
    background: transparent;

    .fancybox__counter {
      line-height: 40px;
    }

    .carousel__button {
      height: 40px;
      width: 40px;
    }

    .fancybox__toolbar__items--right {
      padding-right: 20px;

      .fancybox__button--close {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 0px;
      }
    }
  }

  .fancybox__nav {
    padding-left: 20px;
    padding-right: 20px;
    .carousel__button {
      top: unset;
      bottom: 80px;
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 0px;
      &.is-next {
        right: 20px;
      }
      &.is-prev {
        left: 20px;
      }
    }
  }
}
