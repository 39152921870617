.wrap-virtual-gallery-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #ffffff;

  .header {
    padding-top: 20px;
    padding-bottom: 20px;
    position: sticky;
    top: 0;
    background-color: #000030;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    .logo {
      position: absolute;
      left: 20px;
      height: 40px;
      width: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    span {
      font-family: 'GT Walsheim Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
    }
  }

  .body {
    height: calc(100% - 150px);
    width: 100%;
    padding: 20px 10px 20px 10px;
    overflow-y: auto;
    overflow-x: hidden;

    .virtual-item {
      h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'GT Walsheim Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 115%;
        color: #000030;

        &::after {
          content: '';
          height: 1px;
          background: #000;
          display: block;
          margin-left: 10px;
          width: 20px;
        }
      }
    }

    .wrap-list-images {
      display: grid;
      gap: 10px;
      grid-template-columns: calc(50% - 5px) calc(50% - 5px);
      margin-bottom: 25px;

      .item {
        img {
          width: 100%;
          height: 100px;
          object-fit: cover;
        }
      }
    }
  }
}
