.wrap-virtual-tour {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1061;
  height: calc(100% - 70px);
  width: 100%;
  text-align: center;
  display: inline-block;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  .bg-image {
    background-color: #ffffff;
    width: 100%;
    height: calc(100% - 0px);
    top: 0px;

    .header {
      position: absolute;
      top: 20px;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 20px;
      padding-left: 20px;

      .logo {
        height: 40px;
        width: 40px;
      }

      .close-btn {
        width: 40px;
        height: 40px;
        padding: 0;
        border: 0;
        cursor: pointer;
        border-radius: 0px;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.3);
        svg {
          fill: none;
          stroke: #ffffff;
          width: 50%;
          height: 50%;
          stroke-width: 2.5px;
          stroke-linejoin: bevel;
          stroke-linecap: round;
          filter: none;
        }
      }
    }

    .nav-btn {
      position: absolute;
      z-index: 1;
      bottom: 30px;
      width: 100%;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;

      .dots-container {
        width: calc(100% - 120px);
        background: transparent;
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;

        .wrap-dots {
          pointer-events: all;
          position: absolute;
          z-index: 1;
          display: flex;
          flex-direction: row;

          .dot-item {
            width: 8px;
            height: 8px;
            background: rgba(255, 255, 255, 0.42);
            backdrop-filter: blur(5px);
            border-radius: 50%;
            margin-right: 10px;
            cursor: pointer;

            &:last-child {
              margin: 0px;
            }

            &.active {
              background-color: #ffffff;
              border-color: #ffffff;
            }
          }
        }
      }

      button {
        width: 40px;
        height: 40px;
        border: 0px;
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(5px);
        color: #ffffff;
        border-radius: 0px;
        svg {
          stroke-width: 2.5px;
        }
      }
    }

    .img-fluid {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .img-panorama {
      width: 100%;
      height: 100%;
    }


  }

  .black-content {
    background-color: #15131C;
    height: 15px;
    width: 100%;
    top: calc(100% - 15px);
  }

}
