.wrap-guide-popup-mobile {
  position: absolute;
  z-index: 1060;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(45, 45, 45, 0.5);
  backdrop-filter: blur(5.5px);
  display: flex;
  justify-items: center;
  align-items: center;

  .wrap-popup-content {
    width: 90%;
    height: fit-content;
    margin: 0 auto;
  }

  .popup-body {
    .wrap-intro-description {
      background-color: #ffffff;
      padding: 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .intro-description {
        margin-bottom: 20px;
        text-align: center;

        .title {
          font-family: "GT Walsheim Pro";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: #000030;
        }

        .description {
          font-family: "PT Serif";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #000030;
          margin-top: 10px;
        }
      }

      .btn-explore {
        width: fit-content;
      }
    }

    .wrap-intro-action {
      padding: 30px 60px;
      background-color: #000030;
      display: flex;
      justify-content: center;
      align-items: center;

      .item {
        margin-bottom: 15px;

        &:last-child {
          margin: 0px;
        }

        span {
          margin-left: 50px;
          font-family: "GT Walsheim Pro";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 34px;
          color: #ffffff;
        }
      }
    }

    .intro-item {
      text-align: center;
    }
  }
}

.wrap-intro-future-btn {
  position: absolute;
  z-index: 100;
  width: 200px;
  right: 50px;
  bottom: 180px;
  background-color: transparent;
  padding-right: 10px;
  padding-bottom: 30px;
  text-align: right;
  border-right: 2px solid #FFFFFF;

  span {
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
  }
}
