.wrap-category-detail-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1060;
  background: rgba(45, 45, 45, 0.5);
  backdrop-filter: blur(5.5px);

  .tray {
    height: 40px;
  }

  .content {
    padding: 20px 20px;
    background-color: #000030;
    height: calc(100% - 40px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .body {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;
        padding-top: 10px;
        button {
          width: 40px;
          height: 40px;
          border: 0px;
          background: rgba(255, 255, 255, 0.1);
          color: #ffffff;
          border-radius: 0px;
          padding: 0px;
          .close-btn {
            fill: none;
            stroke-width: 2.5px;
            width: 50%;
            height: 50%;
            stroke: #ffffff;
          }
        }
        span {
          font-family: 'GT Walsheim Pro';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: #FFFFFF;
        }
      }

      .wrap-list-categories {
        padding-top: 20px;

        .category {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #FFFFFF;
          padding: 10px 0px 20px 0px;
          border-top: 1px solid rgba($color: #ffffff, $alpha: 0.2);

          span {
            font-family: 'PT Serif';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 34px;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
