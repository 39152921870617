.wrap-legend-sidebar-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1060;
  background: rgba(45, 45, 45, 0.5);
  backdrop-filter: blur(5.5px);

  .tray {
    height: 40px;
  }

  .content {
    padding: 20px 20px;
    background-color: #000030;
    height: calc(100% - 40px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .body {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;
        padding-top: 10px;
        button {
          width: 40px;
          height: 40px;
          border: 0px;
          background: rgba(255, 255, 255, 0.1);
          color: #ffffff;
          border-radius: 0px;
          padding: 0px;
          svg {
            fill: none;
            stroke-width: 2.5px;
            width: 50%;
            height: 50%;
            stroke: #ffffff;
          }
        }
        span {
          font-family: 'GT Walsheim Pro';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: #FFFFFF;
        }
      }

      .wrap-list-categories {
        padding-top: 20px;
        .category {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #FFFFFF;
          padding: 10px 0px 20px 0px;
          border-top: 1px solid rgba($color: #ffffff, $alpha: 0.2);

          .color-key {
            width: 15px;
            height: 15px;
            margin-right: 10px;
          }

          div {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-family: 'GT Walsheim Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #FFFFFF;
          }
        }
      }
    }

    .footer {
      .wrap-colour-key {
        .title {
          font-family: 'PT Serif';
          font-style: normal;
          font-weight: 200;
          font-size: 16px;
          line-height: 34px;
          color: #FFFFFF;
          opacity: 0.49;
          text-transform: unset;
        }
        .list-group {
          background-color: #FFFFFF;
          border-radius: 0px;
          padding: 10px 22px;
          border-bottom: 5px solid #000030;

          .list-group-item {
            padding: 0px;
            font-family: 'PT Serif';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 34px;
            display: flex;
            align-items: center;
            color: #000030;
            display: flex;
            align-items: center;
            flex-direction: row;

            .color {
              width: 15px;
              height: 15px;
              margin-right: 17px;

              &.existing-dev {
                background-color: #000030;
              }

              &.park-recreate {
                background-color: #A1B0D9;
              }

              &.future-dev {
                background-color: #F0C99E;
              }

              &.walking-tracks {
                background-color: #63E3A6;
              }

              &.mountain-bike-trails {
                background-color: #DF8988;
              }
            }
          }
        }
      }
    }
  }

  &.collapsed {
    bottom: 50px;
    right: 20px;
  }
}
