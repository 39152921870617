.wrap-guide-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 35vw;
  min-width: 300px;
  background-color: #FFFFFF;
  max-height: 40vh;
  margin: auto;
}

.wrap-body {
  display: flex;
  flex-direction: column;
  padding: 60px;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
}

.link {
  border: 1px solid #000000;
  border-radius: 0px;
  padding: 16px 54px;
  background-color: #ffffff;
}


.close-btn {
  position: absolute;
  top: 40px;
  right: 49px;
  cursor: pointer;
}

