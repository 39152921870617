.wrap-video-intro-mobile {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: #000030;
  transition: all 1s;

  .header {
    position: absolute;
    top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 1061;
    height: 40px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .wrap-logo {
      img {
        height: 100%;
      }
    }
    .wrap-btn-control-video {
      button {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);
        border: 0px;
        width: fit-content;
        height: 40px;
        color: #FFFFFF;
        font-family: 'GT Walsheim Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 17px;
        opacity: 0.8;
        text-align: center;
      }
    }
  }

  .intro-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    object-fit: contain;
    z-index: -1;
  }



  .btn-skip-video {
    position: absolute;
    bottom: 80px;
    left: 45px;
    font-weight: 700;
    border-radius: 0px;
    font-size: 15px;
    line-height: 20.25px;
    background: #fff;
    width: 193px;
    height: 51px;
    @media only screen and (max-width: 1366px) {
      bottom: 150px;
    }
  }
}
